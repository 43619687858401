import * as React from 'react'
import HotelSkeleton from '@components/HotelAccordion/HotelSkeleton'
import { getPriceOrNull } from '../../helpers'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { IHotel, IRoomType } from '../../store/data'
import TwoColumnsGrid from '../TwoColumnsGrid'
import RoomTypeItem from './RoomTypeItem'

type RoomTypesProps = {
  hotel?: IHotel | null
  numberBets: number | null
  onLoadRoomType: (data: IRoomType) => void
  onSelect: (roomType: IRoomType) => void
  selectedRoomType?: IRoomType | null
}

const RoomTypes: React.FunctionComponent<RoomTypesProps> = ({
  hotel,
  numberBets,
  onLoadRoomType,
  onSelect,
  selectedRoomType = null,
}) => {
  const language = useBookingFormStore((state) => state.language)
  const isFetchingHotels = useBookingFormStore((state) => state.isFetchingHotels)
  const setLoadingImages = useBookingFormStore((state) => state.setLoadingImages)

  const roomTypes = (hotel?.roomTypes || [])
    .filter((item) => getPriceOrNull(item.lowest_price, 'amount_per_night', language) !== 0)
    .filter((item) => (numberBets !== null ? item.beds === numberBets : true))

  React.useEffect(() => {
    const totalImageCount = roomTypes.filter((roomType) => !!roomType.image).length
    setLoadingImages(totalImageCount)
  }, [roomTypes, setLoadingImages])

  return (
    <TwoColumnsGrid>
      {!isFetchingHotels &&
        roomTypes.map((item) => (
          <RoomTypeItem
            data={item}
            key={item.id}
            onLoadRoomType={() => onLoadRoomType(item)}
            onSelect={() => onSelect(item)}
            selected={item?.id === selectedRoomType?.id}
          />
        ))}
      {isFetchingHotels && Array.from(Array(6).keys()).map((item) => <HotelSkeleton key={item} />)}
    </TwoColumnsGrid>
  )
}

export default RoomTypes
